.LandingPage {
    margin: auto;
    font-family: TitilliumWeb;
    background-color: #F7F3E3;
}
.LandingPage > div > * {
    margin-left: 10px;
    margin-right: 10px;
}
.LandingPage > *:not(div) {
    padding: 0 8px 0 14px;
}
.LandingPage > p {
    font-size: 1.3em;
}
a, input[type=button]{
    border: none;
    font-size: 1.2em;
    padding: 15px;
    background-color: #21A0A0;
    color: #F7F3E3;
}

h1, h2, h3, h4 {
    margin-top: 25px !important;
    margin-bottom: 5px;
    font-size: 1.2em;
}

p {
    margin-bottom: 20px;
    font-size: 1.1em
}
.skip-wrapper {
    margin: 0 !important;
    padding: 0 !important;
}

.simple-link {
    font-size: 1em;
    padding: 0 !important;
    margin: 0 5px;
}

.lesson-btn {
    border: 1px solid black;
    padding:  10px 18px !important;
    text-align: center;

    color: white;
    background-color: #D36135;
}

.lesson-btn:hover {
    color: #D36135;
    background-color: white;
}
