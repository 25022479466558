.Footer {
    background-color: #21A0A0;
    color: #F7F3E3;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
    align-items: center;
}
.Footer > p {
    margin: 0;
    padding: 0;
}

.Footer input {
    color: black;
    background-color: #F7F3E3;
    font-size: 1em;
}