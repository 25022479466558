html, body, #root {
  height: 100%;
}

.App {
  font-family: TitilliumWeb, lora, sans-serifl, serif;
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}
.App > * {
  padding: 30px calc((100% - 850px) / 2);
  margin-left: 10px;
  margin-right: 10px;
}

.App > .footer {
  padding: 30px 0 !important;
}
h1 {
  color: #D36135;
  font-size: 2.5em;
}
h2 {
  font-size: 1.8em;
}
h3 {
  font-weight: 300;
  font-size: 1.5em;
  padding-right: 30px
}
