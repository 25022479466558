.choice-quiz {
    background-color: white;
    padding: 15px;
    max-width: 600px;
    margin: 0 auto 30px !important;
}

h3.correct {
    color: white;
}

.choice-quiz.correct {
    background-color: green;
}

.choice-quiz > ul {
    display: inline-block;
    padding-left: 10px;
}

.choice-quiz > * > .option {
    background-color: white;
    border: 1px solid black;
    list-style: none;
    padding: 15px;
    margin-bottom: 7px;
}

.option.correct:not(.selected) {
    border: 1px solid lightgrey;
    color: lightgrey;
}

.selected.option:not(.correct) {
    background-color: green;
    color: white;
}

.btn-wrap {
    display: flex;
    justify-content: flex-end;
}

.btn-wrap > input[type=button] {
    display: block;
    min-width: 150px;
}

.btn-wrap > p {
    background-color: white;
    padding: 8px;
    width: 100%;
    text-align: center;
}

.option .index {
    border: 1px solid black;
    border-radius: 50px;
    padding: 5px 13px;
    margin-right: 10px;
}
