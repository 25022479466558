.about-me {
    background-color: #F7F3E3;
}

h1 {
    color: #D36135;
    font-size: 2.5em;
}
h3 {
    font-weight: 300;
    font-size: 1.5em;
    padding-right: 30px
}
.headImage {
    margin: auto;
}

input[type=button] {
    border: none;
    font-size: 1.2em;
    padding: 15px;
    background-color: #21A0A0;
    color: #F7F3E3;
}

h1, h2, h3 {
    margin-top: 0;
}
