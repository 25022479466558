
.quote {
    background-color: white;
    padding: 15px;
    margin-bottom: 30px;

    display:inline-block;
    max-width: 600px;

    border: 1px solid #364958;

    -webkit-box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.75);
    box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.75);
}

.quote > p {
    font-style: italic;
}

.quote > h4 {
    margin: 0;
    padding: 0;
}

.code {
    margin-top: 20px;
    margin-bottom: 20px;

    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 18px;

    font-size: 1em;
    font-family: 'courier new';

    background-color: #364958;
    color: #F7F3E3;

    border: 1px solid #ddd;
    border-left: 3px solid #f36d33;
    page-break-inside: avoid;
    line-height: 2em;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    display: block;
    word-wrap: break-word;
}

.variable {
    color: lightblue;
}

.function {
    color: lightgreen;
}

.function, .variable {
    font-family: 'courier new';
    background-color: #364958;
    padding: 0px 4px;
}

.function {
    padding-right: 0;
}

img {
    max-width: 100%;
}
