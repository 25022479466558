.divided-operator > h3 {
    background-color: #F7F3E3;
    color: #364958;
    font-weight: 600;
}
.divided-operator > table {
    border: 1px solid black;
    border-collapse: collapse;
}
.divided-operator * td, .divided-operator * th {
    border: 1px solid black;
}
