body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F3E3;
}

a {
  color: #D00000
}

@font-face {
  font-family: 'TitilliumWeb';
  src: url('fonts/titillium-web/TitilliumWeb-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'lora';
  src: url('fonts/lora/Lora-VariableFont_wght.ttf') format('truetype')
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
