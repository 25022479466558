.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #21A0A0B0;
}
.PopoutMenu {
    position: fixed;
    top: 0;
    right: -8px;
    width: 350px;
    height: 100%;

    border-left: 7px double #D00000;

    background-color: white;
    margin: 0 8px 8px 0;
    padding: 15px 0;

    display:flex;
    flex-direction: column;
    justify-content: flex-end;
}
.PopoutMenu > h3 {
    flex-grow: 1;
    padding-left: 20px;
}
.filler {
    flex-basis: 80px;
    width: 100%;
    height: 50px;
}
p {
    margin-top: 0;
}
.title-wrapper {
    display: flex;
}
.title-wrapper > * {
    margin-top: 0;
}
.title-wrapper > img {
    cursor: pointer;
    height: 30px;
}
.PopoutMenu > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.PopoutMenu > li {
    font-size: 1.6em;
    cursor: pointer;
    padding: 0.7em 15px;
}
.PopoutMenu > li:hover {
    background-color: #364958;
    color: white;
}