.Menu {
    border-bottom: 1px solid #21A0A0;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
}
a {
    background-color: unset;
    color: #364958;
    padding: 0;
    padding-right: 30px !important;
    white-space: nowrap;
    display: inline-block;
}
.center {
    margin: 0 auto
}
.Menu > * {
    font-family: TitilliumWeb, sans-serif;
}
.underline-me {
    text-decoration: underline;
}
.selected {
    font-weight: 600
}