.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #21A0A0B0;
}
.ContactMePopup {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    margin: 0 8px 8px 0;
    width: 90%;
    padding: 15px;
    -webkit-box-shadow: 10px 8px 3px 0px rgba(0,16,33,0.86);
    -moz-box-shadow: 10px 8px 3px 0px rgba(0,16,33,0.86);
    box-shadow: 10px 8px 3px 0px rgba(0,16,33,0.86);
}
p {
    margin-top: 0;
}
.title-wrapper {
    display: flex;
}
.title-wrapper > * {
    margin-top: 0;
}
.title-wrapper > img {
    cursor: pointer;
    height: 30px;
}