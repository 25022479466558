.function-description {
}
.function-table {
    width: 100%;
    background-color: white;
    margin-bottom: 15px;
    padding: 8px 0;

    border: 1px solid black;
    border-left: 6px solid #D36135;
}
.function-table td {
    text-align: center;
    width: 20%
}

.arrow {
    font-size: 30px;
}
